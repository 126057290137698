import React from "react";

export const Spinner = ({ extraClass = "text-info" }) => {
  const style = {
    width: "3rem",
    height: "3rem"
  }

  return (
    <div
      className={`spinner-border m-4 ${extraClass}`}
      role="status"
      style={style}
    >
      <span className="visually-hidden">Loading...</span>
    </div>
  );
};
