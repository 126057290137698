import React, { Fragment } from "react";
import { Link } from "react-router-dom";

export const GroupsTable = ({ data, levelID, isRegistrationOpen, handleShowModal }) => {
  const tableStyle = {
    width: "78%",
    margin: "auto",
  };
  const headerStyle = {
    backgroundColor: "rgb(245,245,245)",
  };
  const setTableStyleBaseOnLevel = (levelID) => {
    let tableColor = "";
    switch (levelID) {
      case 1:
        tableColor = "table-info";
        break;
      case 2:
        tableColor = "table-success";
        break;
      case 3:
        tableColor = "table-danger";
        break;
      case 4:
        tableColor = "table-info";
        break;
      case 5:
        tableColor = "table-success";
        break;
      case 6:
        tableColor = "table-danger";
        break;
      case 7:
        tableColor = "table-warning";
        break;
      case 8:
        tableColor = "table-warning";
        break;
      default:
        tableColor = "table-info";
    }

    return tableColor;
  };

  return (
    <Fragment>
      {data && (
        <div className={"table-responsive"}>
        <table
          className={`table ${setTableStyleBaseOnLevel(levelID)}`}
          style={tableStyle}
        >
          <thead style={headerStyle}>
            <tr>
              <th>Pływalnia</th>
              <th>Dzień</th>
              <th>Godzina</th>
              <th>Termin</th>
              <th>Koszt</th>
              {isRegistrationOpen && <th></th>}
            </tr>
          </thead>
          <tbody>
            {data.map((listValue, index) => {
              return (
                <Fragment>
                  <tr key={index}>
                    <td>
                      <Link to="/pools">{listValue.pool}</Link>
                    </td>
                    <td>{listValue.day}</td>
                    <td>{listValue.time}</td>
                    <td>{listValue.dates}</td>
                    <td>{listValue.cost}</td>
                    {isRegistrationOpen && (
                      <td>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                          onClick={() => handleShowModal(listValue.id, listValue.capacity)}
                        >
                          Zapisz
                        </button>
                      </td>
                    )}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </table>
        </div>
      )}
    </Fragment>
  );
};
