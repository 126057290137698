import React, { Fragment } from "react";
import { Spinner } from "../components/spinner/spinner";
import { ContentRow } from "../components/content-row/content-row";
import { PageHeading } from "../components/page-heading/page-heading";
import { usePageContent } from "../hooks";

export const Kadra = () => {
  const pageName = "kadra";
  const { pageStatus, pageData } = usePageContent(pageName);

  return (
    <Fragment>
      <PageHeading
        image="https://storage.googleapis.com/miko-plywanie-public/images/kadra.jpg"
        header="<h1>Niesamowite Doświadczenie</h1>
          <h2>
            <span className='text-muted'>Genialna Prezencja</span>
          </h2>"
      />
      {pageStatus === "loading" || !pageData ? (
        <div className="d-flex justify-content-center">
          <Spinner />
        </div>
      ) : (
        <Fragment>
          <ContentRow
            header="Doświadczenie <br /> oraz Podążanie za Nowymi Trendami"
            content={pageData[1].name}
            image={
              "https://storage.googleapis.com/miko-plywanie-public/images/irmina.jpg"
            }
            headerColor={"yellow"}
            isImgLeft={false}
          />
          <ContentRow
            header="Radość z Pływania,<br /> To Podstawa do innych Sportów"
            content={pageData[2].name}
            image={
              "https://storage.googleapis.com/miko-plywanie-public/images/magda.jpg"
            }
            headerColor={"blue"}
            isImgLeft={true}
          />
          <ContentRow
            header="Ludzie, zwierzęta, woda <br /> Pozytywnie uzależniają"
            content={pageData[7].name}
            image={
              "https://storage.googleapis.com/miko-plywanie-public/images/ada.jpg"
            }
            headerColor={"red"}
            isImgLeft={false}
          />
          <ContentRow
            header="Pływacki Sukces <br /> Można osiągnać w każdym wieku"
            content={pageData[5].name}
            image={
              "https://storage.googleapis.com/miko-plywanie-public/images/jasiek.jpg"
            }
            headerColor={"green"}
            isImgLeft={true}
          />
          <ContentRow
            header="Z uśmiechem <br />Trenuje się łatwiej"
            content={pageData[9].name}
            image={
              "https://storage.googleapis.com/miko-plywanie-public/images/filip.jpg"
            }
            headerColor={"green"}
            isImgLeft={false}
          />
          <ContentRow
            header="Pływanie, nauczanie, projektowanie <br /> To komplet doskonały"
            content={pageData[8].name}
            image={
              "https://storage.googleapis.com/miko-plywanie-public/images/ola.jpg"
            }
            headerColor={"yellow"}
            isImgLeft={true}
          />
          <ContentRow
            header="Postępy uczniów <br /> Dają najwięcej radości"
            content={pageData[6].name}
            image={
              "https://storage.googleapis.com/miko-plywanie-public/images/zuza.jpg"
            }
            headerColor={"pink"}
            isImgLeft={false}
          />
        </Fragment>
      )}
    </Fragment>
  );
};
