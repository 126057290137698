import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { rest } from '../rest/rest';

// examples:
// https://github.com/reduxjs/redux-essentials-example-app/blob/tutorial-steps/src/features/posts/postsSlice.js
// https://redux.js.org/tutorials/essentials/part-4-using-data#updating-post-entries
export const fetchGroups = createAsyncThunk('groups/fetchGroups', async () => {
  const response = await rest.get(`/v1/groups`);
  return response.data.data;
});

export const fetchGroupCount = createAsyncThunk(
  'groups/fetchGroupCount',
  async ({ groupID }) => {
    const response = await rest.get(`/v1/groups/${groupID}/count`);
    return response.data.data;
  }
);

export const addGroupParticipant = createAsyncThunk(
  'groups/addGroupParticipant',
  async (
    { groupID, first, last, email, phone, isHealthy, isPhotoOk },
    { rejectWithValue }
  ) => {
    try {
      const response = await rest.post(`/v1/groups/${groupID}/participants`, {
        first,
        last,
        phone,
        email,
        isHealthy,
        isPhotoOk,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const removeGroupParticipantsData = createAsyncThunk(
  'groups/removeGroupParticipantsData',
  async ({ groupID, email, userID }, { rejectWithValue }) => {
    try {
      const response = await rest.post(
        `/v1/groups/${groupID}/participants/${userID}`,
        {
          email,
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const groupsSlice = createSlice({
  name: 'groups',
  initialState: {
    statusFetch: 'idle',
    statusAddParticipant: undefined,
    errorAddParticipant: undefined,
    statusRemoveData: undefined,
    errorRemoveData: undefined,
    statusFetchGroupCount: undefined,
    groupCount: undefined,
    opErr: null,
    data: [],
  },
  reducers: {
    resetAddState(state, action) {
      state.errorAddParticipant = undefined;
      state.statusAddParticipant = undefined;
    },
  },
  extraReducers: {
    [fetchGroups.pending]: (state, action) => {
      state.statusFetch = 'loading';
    },
    [fetchGroups.fulfilled]: (state, action) => {
      state.statusFetch = 'succeeded';
      if (action.payload) {
        state.data = [...action.payload];
      }
    },
    [fetchGroups.rejected]: (state, action) => {
      state.statusFetch = 'failed';
    },
    [addGroupParticipant.pending]: (state, action) => {
      state.statusAddParticipant = 'loading';
    },
    [addGroupParticipant.fulfilled]: (state, action) => {
      state.statusAddParticipant = 'succeeded';
      state.errorAddParticipant = undefined;
    },
    [addGroupParticipant.rejected]: (state, action) => {
      state.statusAddParticipant = 'failed';
      state.errorAddParticipant = convertAddError(action.payload.data.error);
    },
    [removeGroupParticipantsData.pending]: (state, action) => {
      state.statusRemoveData = 'loading';
    },
    [removeGroupParticipantsData.fulfilled]: (state, action) => {
      state.statusRemoveData = 'succeeded';
    },
    [removeGroupParticipantsData.rejected]: (state, action) => {
      state.statusRemoveData = 'failed';
      state.errorRemoveData = convertRemoveError(action.payload.data.error);
    },
    [fetchGroupCount.pending]: (state, action) => {
      state.statusFetchGroupCount = 'loading';
    },
    [fetchGroupCount.fulfilled]: (state, action) => {
      state.statusFetchGroupCount = 'succeeded';
      state.groupCount = action.payload.count;
    },
    [fetchGroupCount.rejected]: (state, action) => {
      state.statusFetchGroupCount = 'failed';
    },
  },
});

const convertAddError = (error) => {
  switch (error) {
    case 'ErrNameNotProvided':
      return 'Prosze sprawdzić imie';
    case 'ErrLastNotProvided':
      return 'Prosze sprawdzić nazwisko';
    case 'ErrEmailNotProvided':
      return 'Prosze sprawdzić adres email';
    case 'ErrInvalidEmail':
      return 'Prosze sprawdzić adres email';
    case 'ErrNotHealthy':
      return 'Prosze czy dziecko jest zdrowe';
    case 'ErrPhoneInvalid':
      return 'Prosze sprawdzić numer telefonu';
    case 'ErrParticipantAlreadyInGroup':
      return 'Twoje dziecko jest już zapisane. Sprawdź czy potrzebujesz następnego miejsca.';
    default:
      return 'unknown';
  }
};

const convertRemoveError = (error) => {
  switch (error) {
    case 'ErrInvalidEmail':
      return 'Prosze sprawdzić adres email';
    default:
      return 'Wystapił Błąd';
  }
};

export const { resetAddState } = groupsSlice.actions;

export default groupsSlice.reducer;
