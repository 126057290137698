import React, { Fragment, useEffect } from "react";
import { PageHeading } from "../../components/page-heading/page-heading";
import { HeadingRow } from "../../components/content-row/heading-row";
import { ContentRow } from "../../components/content-row/content-row";
import { ImageRow } from "../../components/content-row/image-row";
import { RegistrationForm } from "./registration-form";
import { Closed } from "./closed";
import { useDispatch, useSelector } from "react-redux";
import { fetchIsRegistrationOpen } from "../../services/settings-slice";
import { Spinner } from "../../components/spinner/spinner";

export const Zapisy = () => {
  const dispatch = useDispatch();
  const isRegistrationOpen = useSelector(
    (state) => state.settings.isRegistrationOpen
  );
  const isRegistrationOpenStatus = useSelector(
    (state) => state.settings.statusFetch
  );

  useEffect(() => {
    if (isRegistrationOpen === undefined) {
      dispatch(fetchIsRegistrationOpen());
    }
  });

  return (
    <Fragment>
      <PageHeading
        image="https://storage.googleapis.com/miko-plywanie-public/images/zapisy2.jpg"
        header="<h1 style='color:black'>Bez Wychodzenia z Domu</h1>
              <h2 className='text-muted'>Oraz Kolejek</h2>"
      />
      <HeadingRow
        headerColor="yellow"
        header="<h2>Skorzystaj z<br> Próbnej Lekcji</h2>"
      />
      <div className="container marketing">
        <div className="row g-0 featurette align-items-center">
          <div className={`col-sm-7`}>
            {isRegistrationOpenStatus === "loading" ||
            isRegistrationOpen === undefined ? (
              <div className="d-flex justify-content-center">
                <Spinner />
              </div>
            ) : isRegistrationOpen ? (
              <RegistrationForm />
            ) : (
              <Closed />
            )}
          </div>
          <div className="col-sm-5">
            <ImageRow image="https://storage.googleapis.com/miko-plywanie-public/images/zapisy.jpg" />
          </div>
        </div>
      </div>
      <ContentRow
        header="<h2>Konkurencyjne Ceny<br> Nieporównywalna Jakość</h2>"
        headerColor="blue"
        isImgLeft={true}
        image="https://storage.googleapis.com/miko-plywanie-public/images/oplaty.jpg"
        content="<h3>Opłaty</h3>
        <p className=' lead'>
          <span className=' muted'>Od 1.05.2011r </span> wpłaty za lekcje przyjmujemy tylko przelewem na konto :
        </p>
        <div className=' alert alert-info' style=' width:85%;'>
      Odbiorca: <strong>MIKO szkółka pływacka </strong><br>
      Numer konta: <strong>98 1140 2004 0000 3002 4853 2290 </strong> <br>
      Tytuł: <strong><em>nauka pływania, semester (pierwszy, drugi)</em> - <em>imię i nazwisko
      pływaka</em> - <em>dzień i godz. zajęć</em></strong> <br>
      <br> Na przykład:<em> nauka pływania pierwszy semestr - Jaś Konarzewski - sobota 14.00 </em><br>
      </div>
        <div className='alert alert-warning' style='width:85%;'>
          Informacje na temat kosztów zajęć, można uzyskać w zakładce <a
          href='https://www.miko-plywanie.pl/offer'>'Oferta'</a>
        </div>"
        textAlignment=" "
      />
    </Fragment>
  );
};
