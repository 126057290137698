import React, { Fragment } from "react";
import Parser from "html-react-parser";
import { ImageRow } from "./image-row";
import { GroupsTable } from "./groups-table";

export const ContentRowWithTable = ({
  content,
  contentHeader,
  image,
  isImgLeft,
  tableContent,
  levelID,
  isRegistrationOpen,
  handleShowModal,
}) => {
  return (
    <Fragment>
      <section>
        <div className="container marketing">
          <div className="row g-0 featurette align-items-center justify-content-center">
            {isImgLeft ? (
              <Fragment>
                <div className="col-sm-5">
                  {image && <ImageRow image={image} />}
                </div>
                <div className="col-sm-7 text-center">
                  {contentHeader && Parser(contentHeader)}
                  <GroupsTable
                    data={tableContent}
                    levelID={levelID}
                    isRegistrationOpen={isRegistrationOpen}
                    handleShowModal={handleShowModal}
                  />
                  <p>
                    <small className="text-muted">{Parser(content)}</small>
                  </p>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <div className="col-sm-7 text-center">
                  {contentHeader && Parser(contentHeader)}
                  <GroupsTable
                    data={tableContent}
                    levelID={levelID}
                    isRegistrationOpen={isRegistrationOpen}
                    handleShowModal={handleShowModal}
                  />
                  <p>
                    <small className="text-muted">{Parser(content)}</small>
                  </p>
                </div>
                <div className="col-sm-5">
                  {image && <ImageRow image={image} />}
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </section>
    </Fragment>
  );
};
