import React from 'react';

export const NoMatch = () => {
  return (
    <div className="container">
      <div className="row g-0 mt-4">
        <div className="col text-center">
          <div className="alert alert-danger" role="alert">
            Sorry{' '}
            <span role="img" aria-label="emoji">
              ⚠️
            </span>
            <br />
            Seems like we do not have this yet!
          </div>
        </div>
      </div>
    </div>
  );
};
