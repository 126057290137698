import React from "react";
import { ProgressBar } from "react-bootstrap";

export const StepOne = ({setDifficulty, difficulty, shouldDisplay, setShouldDisplay}) => {
  return (
    <fieldset
      className={shouldDisplay === 0 ? "" : "d-none"}
    >
      <legend>Krok 1.</legend>
      <hr />
      <ProgressBar striped variant="info" now={33} />
      <section className={"mt-3"}>
        <label>Chcesz:</label>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="flexRadioDefault"
            value={1}
            id="flexRadioDefault1"
            onClick={(e) => {
              setDifficulty(e.currentTarget.value);
            }}
          />
          <label className="form-check-label" htmlFor="flexRadioDefault1">
            <i>Nauczyć się pływać (w ogóle nie umiem pływać)</i>
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="flexRadioDefault"
            value={7}
            id="flexRadioDefault2"
            onClick={(e) => {
              setDifficulty(e.currentTarget.value);
            }}
          />
          <label className="form-check-label" htmlFor="flexRadioDefault2">
            <i>Kontynuować naukę pływania (uczę się z MIKO od roku)</i>
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="flexRadioDefault"
            value={2}
            id="flexRadioDefault3"
            onClick={(e) => {
              setDifficulty(e.currentTarget.value);
            }}
          />
          <label className="form-check-label" htmlFor="flexRadioDefault3">
            <i>Doskonalić swoją technikę (pływam 1-2 stylami)</i>
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="flexRadioDefault"
            value={3}
            id="flexRadioDefault4"
            onClick={(e) => {
              setDifficulty(e.currentTarget.value);
            }}
          />
          <label className="form-check-label" htmlFor="flexRadioDefault4">
            <i>Doskonalić swoją technikę (pływam 3-4 stylami)</i>
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="flexRadioDefault"
            value={8}
            id="flexRadioDefault5"
            onClick={(e) => {
              setDifficulty(e.currentTarget.value);
            }}
          />
          <label className="form-check-label" htmlFor="flexRadioDefault5">
            <i>Chcę doskonalić sportową technikę pływania</i>
          </label>
        </div>
      </section>
      <button
        className="btn btn-success mt-3"
        disabled={!difficulty}
        onClick={() => setShouldDisplay(1)}
      >
        Krok 2
      </button>
    </fieldset>
  )
}