import React, { Fragment } from "react";
import { PageHeading } from "../components/page-heading/page-heading";
import { Spinner } from "../components/spinner/spinner";
import Parser from "html-react-parser";
import { Nav, Tab } from "react-bootstrap";
import { usePageContent } from "../hooks";

export const FAQ = () => {
  const pageName = "faq";
  const { pageStatus, pageData } = usePageContent(pageName);
  return (
    <Fragment>
      <PageHeading
        image="https://storage.googleapis.com/miko-plywanie-public/images/pytaniahead.jpg"
        header="<h1>Każde Pytanie</h1>
	              <h2><span className='text-muted'>Jest Inne</span></h2>"
      />
      {pageStatus === "loading" || !pageData ? (
        <div className="d-flex justify-content-center">
          <Spinner />
        </div>
      ) : (
        <Fragment>
          <div className="row g-0">
            <div className={"col text-center colorhead red"}>
              <h2>
                <h2>
                  Sprawdź Odpowiedzi <br />
                  Na Popularne Pytania
                </h2>
              </h2>
            </div>
          </div>
          <div className="container marketing">
            <Tab.Container id="left-tabs-example" defaultActiveKey="q1">
              <div className="row g-0 mt-sm-5 mb-sm-5">
                <div className="col-sm-4">
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="q1">
                        Czy w tym tygodniu są zajęcia?
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="q2">Gdzie uczymy pływać?</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="q3">Co zabrać na lekcje?</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="q4">
                        Ile kosztują lekcje pływania?
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="q5">
                        Czy oferujemy indywidualne lekcje pływania?
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="q6">
                        Czy rodzic/instruktor przebywa w wodzie podczas lekcji?
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="q7">
                        Co z lekcjami, które przepadły z powodu nieobecności
                        ucznia?
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="q8">Ile osób liczy grupa?</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="q9">
                        Ile czasu trwa lekcja pływania?
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="q10">
                        Twoje pytanie nie znajduje się na liście?
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div className="col-sm-7 offset-sm-1">
                  <Tab.Content>
                    <Tab.Pane eventKey="q1">
                      <h4 className="lead">Najbliższe Anulowane Zajęcia</h4>
                      {Parser(pageData[1].name)}
                    </Tab.Pane>
                    <Tab.Pane eventKey="q2">
                      <h4 className="lead">
                        Nasze zajęcia odbywają się na pływalniach
                      </h4>
                      {Parser(pageData[2].name)}
                    </Tab.Pane>
                    <Tab.Pane eventKey="q3">
                      <h4 className="lead">Najniezbędniejsze Rzeczy</h4>
                      {Parser(pageData[3].name)}
                    </Tab.Pane>
                    <Tab.Pane eventKey="q4">
                      <h4 className="lead">To zależy od pływalni</h4>
                      {Parser(pageData[4].name)}
                    </Tab.Pane>
                    <Tab.Pane eventKey="q5">
                      <h4 className="lead">
                        Lubimy towarzystwo kiedy się uczymy
                      </h4>
                      {Parser(pageData[5].name)}
                    </Tab.Pane>
                    <Tab.Pane eventKey="q6">
                      <h4 className="lead">U nas wszyscy są odpowiedzialni</h4>
                      {Parser(pageData[6].name)}
                    </Tab.Pane>
                    <Tab.Pane eventKey="q7">
                      <h4 className="lead">Razem Możemy Znaleźć Rozwiązanie</h4>
                      {Parser(pageData[7].name)}
                    </Tab.Pane>
                    <Tab.Pane eventKey="q8">
                      <h4 className="lead">Grupy w sam raz</h4>
                      {Parser(pageData[9].name)}
                    </Tab.Pane>
                    <Tab.Pane eventKey="q9">
                      <h4 className="lead">Nie za długo ale intensywnie</h4>
                      {Parser(pageData[10].name)}
                    </Tab.Pane>
                    <Tab.Pane eventKey="q10">
                      <h4 className="lead">Zawsze odpowiadamy na pytania</h4>
                      {Parser(pageData[8].name)}
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};
