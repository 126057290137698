import React, { Fragment, useState } from 'react';
import { PageHeading } from '../components/page-heading/page-heading';
import { HeadingRow } from '../components/content-row/heading-row';
import { ImageRow } from '../components/content-row/image-row';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

export const Pools = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const style = {
    width: '70%',
  };
  return (
    <Fragment>
      <PageHeading
        image="https://storage.googleapis.com/miko-plywanie-public/images/plywalnie.jpg"
        header="<h1>Znajdź Pływalnię</h1>
	            <h2>Blisko Ciebie</h2>"
      />
      <HeadingRow headerColor="pink" header="<h2>Koncertowa</h2>" />
      <div className="container marketing">
        <div className="row g-0 featurette justify-content-center align-items-center">
          <Fragment>
            <div className="col-sm-5">
              <ImageRow image="https://storage.googleapis.com/miko-plywanie-public/images/koncertowa.jpg" />
            </div>
            <div className={`col-sm-7`}>
              <address className="lead">
                <strong>
                  <span className="poolName">UCSiR Pływalnia Koncertowa</span>
                </strong>
                <br />
                ul. Koncertowa 4<br />
                02-787 Warszawa
                <br />
                <abbr title="Telefon">Tel:</abbr> (22) 641 98 38
              </address>
              <button
                type="button"
                className="btn btn-primary mb-4"
                onClick={handleShow}
              >
                Znajdź na mapie <i className="icon-map-marker icon-white"></i>
              </button>
              <div className="alert alert-success" style={style}>
                <p>
                  Na tej pływalni oferujemy zajęcia dla dzieci:
                  <ul>
                    <li>
                      <Link to={'/offer'}>Nauka pływania</Link>
                    </li>
                    <li>
                      <Link to={'/offer'}>
                        Doskonalenie (pływam 1 lub 2 stylami)
                      </Link>
                    </li>
                    <li>
                      <Link to={'/offer'}>
                        Doskonalenie (pływam 3 lub 4 stylami)
                      </Link>
                    </li>
                    <li>
                      <Link to={'/offer'}>
                        Pływanie zaawansowane (pływam b. dobrze 4 stylami)
                      </Link>
                    </li>
                  </ul>
                </p>
              </div>
              <div className="alert alert-info" style={style}>
                <p>
                  Wjazd na parking od ul. Beli Bartoka. Przystanek metra:
                  Ursynów
                </p>
              </div>
              <div className="alert alert-info" style={style}>
                <p>Informacje o pływalni:</p>
                <ul id="infokon">
                  <li>Długość 25m</li>
                  <li>Liczba torów 6</li>
                  <li>Głębokość 100cm-180cm</li>
                </ul>
              </div>
              <div className="alert alert-danger" style={style}>
                <p>
                  Na pasku do szafki mamy zalogowane 60 min (w tym 30 min
                  lekcji), suszarki znajdują się w płatnej strefie. Opiekun może
                  pływać na torach obok, po wykupieniu biletu w kasie.
                </p>
              </div>
            </div>
          </Fragment>
          <Fragment>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>UCSiR Pływalnia Koncertowa</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <iframe
                  width="350vw"
                  height="350"
                  src="https://maps.google.com/maps?f=q&amp;source=s_q&amp;hl=en&amp;geocode=&amp;q=Koncertowa+4,+Warsaw,+Poland&amp;aq=0&amp;oq=koncertowa+4&amp;sll=42.27332,-83.737689&amp;sspn=0.183925,0.363235&amp;ie=UTF8&amp;hq=&amp;hnear=Koncertowa+4,+Warsaw,+Warszawa,+Masovian+Voivodeship,+Poland&amp;t=m&amp;z=14&amp;ll=52.163306,21.029794&amp;output=embed"
                ></iframe>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleClose}
                >
                  Zamknij
                </button>
              </Modal.Footer>
            </Modal>
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};
