import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { rest } from '../rest/rest';

// examples:
// https://github.com/reduxjs/redux-essentials-example-app/blob/tutorial-steps/src/features/posts/postsSlice.js
// https://redux.js.org/tutorials/essentials/part-4-using-data#updating-post-entries
export const fetchIsRegistrationOpen = createAsyncThunk(
  'groups/fetchIsRegistrationOpen',
  async () => {
    const response = await rest.get(`/v1/settings/registrations`);
    return response.data.data;
  }
);

export const settingsSlice = createSlice({
  name: 'groups',
  initialState: {
    statusFetch: 'idle',
    isRegistrationOpen: undefined,
  },
  extraReducers: {
    [fetchIsRegistrationOpen.pending]: (state, action) => {
      state.statusFetch = 'loading';
    },
    [fetchIsRegistrationOpen.fulfilled]: (state, action) => {
      state.statusFetch = 'succeeded';
      if (action.payload) {
        state.isRegistrationOpen = action.payload.isRegistrationOn;
      }
    },
    [fetchIsRegistrationOpen.rejected]: (state, action) => {
      state.statusFetch = 'failed';
    },
  },
});

export default settingsSlice.reducer;
