import { configureStore } from "@reduxjs/toolkit";
import pagesReducer from "./pages-slice";
import groupsReducer from "./groups-slice";
import settingsReducer from "./settings-slice";

export default configureStore({
  reducer: {
    pages: pagesReducer,
    groups: groupsReducer,
    settings: settingsReducer,
  },
});
