import React from "react";
import { Alert } from "react-bootstrap";

export const RemoveSuccess = ({shouldShow, setShouldShow}) => {
  const mikoFont = {
    fontFamily: "kids",
  };

  if (shouldShow) {
    return (
      <Alert variant="success" onClose={() => setShouldShow(false)} dismissible>
        <span className="text-success">
        <strong>Gratulacje!</strong>
      </span>
        <br />
        Operacja powiodła się!
        <br />
        <span style={mikoFont}>M IKO</span>
      </Alert>
    );
  }
};
