import React, { useState } from "react";
import { ProgressBar } from "react-bootstrap";

export const StepThree = ({
  setFirst,
  setLast,
  setPhone,
  setEmail,
  setIsHealthy,
  setIsPhotoOk,
  addParticipantToGroup,
  shouldDisplay,
  setShouldDisplay,
  first,
  last,
  phone,
  email,
  isHealthy,
  addError,
  isLoading,
}) => {
  const [dataProcessing, setDataProcessing] = useState(undefined);
  const mikoFont = {
    fontFamily: "kids",
  };
  const isSubmitEnabled = () => {
    return !first || !last || !phone || !email || !isHealthy || !dataProcessing;
  };
  const shouldShowApiError = (errorMsg) => {
    if (errorMsg === undefined) {
      return false;
    }

    return errorMsg !== "unknown";
  };

  return (
    <fieldset className={shouldDisplay === 2 ? "" : "d-none"}>
      <legend>Krok 3.</legend>
      <hr />
      <ProgressBar striped variant="info" now={100} />
      <section>
        <section className={"ms-2"}>
          <h6 className={"mt-3"}>
            Powiedz nam trochę więcej o uczestniku zajęć.
          </h6>
          <div className="row">
            <label className="col-form-label" htmlFor="inputname">
              Imię
            </label>
            <div className={"col-8"}>
              <input
                required={true}
                className="form-control"
                type="text"
                name="inputname"
                autoComplete="given-name"
                placeholder="np. Jan"
                onChange={(e) => {
                  setFirst(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="row">
            <label className="col-form-label" htmlFor="inputlast">
              Nazwisko
            </label>
            <div className="col-8">
              <input
                required={true}
                className="form-control"
                type="text"
                name="inputlast"
                autoComplete="family-name"
                placeholder="np. Nowak"
                onChange={(e) => {
                  setLast(e.target.value);
                }}
              />
            </div>
          </div>
        </section>
        <section className={"ms-2"}>
          <h6 className={`mt-3`}>
            Powiedz nam trochę więcej o opiekunie uczestnika zajęć.
          </h6>
          <div className="row">
            <label className="col-form-label" htmlFor="inputtel">
              Tel
            </label>
            <div className="col-8">
              <input
                required={true}
                className="form-control"
                type="tel"
                name="inputtel"
                autoComplete="tel"
                min={9}
                max={13}
                placeholder="np. 123456789"
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="row">
            <label className="col-form-label" htmlFor="inputemail">
              Email
            </label>
            <div className="col-8">
              <input
                required={true}
                className="form-control"
                type="email"
                name="inputemail"
                autoComplete="email"
                placeholder="np. jan.nowak@przyklad.pl"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
          </div>
        </section>
        <section className={"ms-2"}>
          <div className="row-fluid mt-2">
            <div className="form-check">
              <input
                required={true}
                className="form-check-input border border-danger"
                type="checkbox"
                value="Y"
                name="zgodaDane"
                onClick={(e) => {
                  setDataProcessing(e.target.checked);
                }}
              />
              <label className="form-check-label">
                Zgadzam się na przetwarzanie moich danych osobowych wyłącznie w
                celu zapisania na zajęcia pływania i do kontaktu w sprawie zajęć
                szkoły pływania <span style={mikoFont}>M IKO</span>. <br />
                <small className="text-muted">
                  Zgoda na przetwarzanie danych osobowych zgodnie z Ustawą z
                  dnia 29 sierpnia 1997r o ochronie danych osobowych (Dz. U. Nr
                  133, poz. 833). Administratorem danych osobowych jest Irmina
                  Konarzewska.
                </small>
              </label>
            </div>
          </div>
          <div className="row-fluid">
            <div className="form-check">
              <input
                required={true}
                className="form-check-input border border-danger"
                type="checkbox"
                name="zdrowie"
                onClick={(e) => {
                  setIsHealthy(e.target.checked);
                }}
              />
              <label className="form-check-label">
                Potwierdzam, że moje dziecko jest zdrowe i nie ma przeciwwskazań lekarskich do udziału w lekcjach pływania.
              </label>
            </div>
          </div>
          <div className="row-fluid">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                name="zdjecia"
                onClick={(e) => {
                  setIsPhotoOk(e.target.checked);
                }}
              />
              <label className="form-check-label">
                Zgadzam się na publikacje zdjęć mojego dziecka w Internecie
              </label>
            </div>
          </div>
        </section>
        <section>
          <div className="row justify-content-between">
            <div className="col-3">
              <button
                className="btn btn-warning mt-3"
                onClick={() => setShouldDisplay(0)}
              >
                Sprawdz
              </button>
            </div>
            <div className="col-3">
              <button
                className="btn btn-success mt-3"
                onClick={addParticipantToGroup}
                disabled={isSubmitEnabled()}
              >
                {isLoading && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                {" "} Zapisz
              </button>
            </div>
          </div>
          {shouldShowApiError(addError) && (
            <div className="row mt-3 justify-content-center">
              <div className="col">
                <div className="alert alert-danger" role="alert">
                  {addError}
                </div>
              </div>
            </div>
          )}
        </section>
      </section>
    </fieldset>
  );
};
