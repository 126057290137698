import React from "react";
import { Alert } from "react-bootstrap";

export const AddSuccess = ({shouldShow, setShouldShow}) => {
  const mikoFont = {
    fontFamily: "kids",
  };

  if (shouldShow) {
    return (
      <Alert variant="success" onClose={() => setShouldShow(false)} dismissible>
        <span className="text-success">
        <strong>Gratulacje!</strong>
      </span>
        <br />
        Rejestracja powiodła się!
        <br />
        <span style={mikoFont}>M IKO</span>
        <br />
        <small className="text text-error">
          Jeśli nie otrzymasz emaila z potwierdzeniem zapisów w ciągu najbliższych
          24 godzin, prosimy o wysłanie smsa na numer 503 940 590
        </small>
      </Alert>
    );
  }
};
