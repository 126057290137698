import { Modal } from "react-bootstrap";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addGroupParticipant,
  fetchGroupCount,
} from "../../services/groups-slice";
import { AddSuccess } from "../registration/addSuccess";
import { AddError } from "../registration/addError";

export const RegistrationModal = ({
  shouldShow,
  handleClose,
  groupID,
  groupCapacity,
}) => {
  const dispatch = useDispatch();
  const addStatus = useSelector((state) => state.groups.statusAddParticipant);
  const addError = useSelector((state) => state.groups.errorAddParticipant);
  const groupCount = useSelector((state) => state.groups.groupCount);
  const [first, setFirst] = useState(undefined);
  const [last, setLast] = useState(undefined);
  const [email, setEmail] = useState(undefined);
  const [phone, setPhone] = useState(undefined);
  const [isHealthy, setIsHealthy] = useState(false);
  const [isPhotoOk, setIsPhotoOk] = useState(false);
  const [dataProcessing, setDataProcessing] = useState(undefined);
  const [showSuccess, setShowSuccess] = useState(true);
  const [showFail, setShowFail] = useState(true);

  const isSubmitEnabled = () => {
    return !first || !last || !phone || !email || !isHealthy || !dataProcessing;
  };
  const shouldShowApiError = (errorMsg) => {
    if (errorMsg === undefined) {
      return false;
    }

    return errorMsg !== "unknown";
  };
  const mikoFont = {
    fontFamily: "kids",
  };
  const isWaitList = (groupCapacity, groupCount) => {
    if (!groupID) {
      return false;
    }

    return groupCount >= groupCapacity;
  };
  const addParticipantToGroup = () => {
    dispatch(
      addGroupParticipant({
        groupID,
        first,
        last,
        email,
        phone,
        isHealthy,
        isPhotoOk,
      })
    );
  };
  const shouldShowSuccess = addStatus === "succeeded" && showSuccess;
  const shouldShowFailure =
    addStatus === "failed" && showFail && addError === "unknown";

  useEffect(() => {
    if (groupID) {
      dispatch(fetchGroupCount({ groupID }));
    }

    if (shouldShow) {
      setFirst(undefined);
      setLast(undefined);
      setEmail(undefined);
      setPhone(undefined);
      setPhone(undefined);
      setIsHealthy(false);
      setIsPhotoOk(false);
      setDataProcessing(undefined);
    }
  }, [shouldShow]);

  return (
    <Modal show={shouldShow} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          Zapisy {isWaitList(groupCapacity, groupCount) && "na listę rezerwową"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddSuccess
          setShouldShow={setShowSuccess}
          shouldShow={shouldShowSuccess}
        />
        <AddError setShouldShow={setShowFail} shouldShow={shouldShowFailure} />
        {!shouldShowFailure && !shouldShowSuccess && (
          <Fragment>
            <section>
              <h6 className={"mt-3"}>
                Powiedz nam trochę więcej o uczestniku zajęć.
              </h6>
              <div className="row">
                <label className="col-form-label" htmlFor="inputname">
                  Imię
                </label>
                <div className={"col-8"}>
                  <input
                    required={true}
                    className="form-control"
                    type="text"
                    name="inputname"
                    autoComplete="name"
                    placeholder="np. Jan"
                    onChange={(e) => {
                      setFirst(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <label className="col-form-label" htmlFor="inputlast">
                  Nazwisko
                </label>
                <div className="col-8">
                  <input
                    required={true}
                    className="form-control"
                    type="text"
                    name="inputlast"
                    autoComplete="family-name"
                    placeholder="np. Nowak"
                    onChange={(e) => {
                      setLast(e.target.value);
                    }}
                  />
                </div>
              </div>
            </section>
            <section>
              <h6 className={`mt-3`}>
                Powiedz nam trochę więcej o opiekunie uczestnika zajęć.
              </h6>
              <div className="row">
                <label className="col-form-label" htmlFor="inputtel">
                  Tel
                </label>
                <div className="col-8">
                  <input
                    required={true}
                    className="form-control"
                    type="tel"
                    name="inputtel"
                    autoComplete="tel"
                    min={9}
                    max={13}
                    placeholder="np. 123456789"
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <label className="col-form-label" htmlFor="inputemail">
                  Email
                </label>
                <div className="col-8">
                  <input
                    required={true}
                    className="form-control"
                    type="email"
                    name="inputemail"
                    autoComplete="email"
                    placeholder="np. jan.nowak@przyklad.pl"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
              </div>
            </section>
            <section>
              <div className="row-fluid mt-2">
                <div className="form-check">
                  <input
                    required={true}
                    className="form-check-input border border-danger"
                    type="checkbox"
                    value="Y"
                    name="zgodaDane"
                    onClick={(e) => {
                      setDataProcessing(e.target.checked);
                    }}
                  />
                  <label className="form-check-label">
                    Zgadzam się na przetwarzanie moich danych osobowych
                    wyłącznie w celu zapisania na zajęcia pływania i do kontaktu
                    w sprawie zajęć szkoły pływania{" "}
                    <span style={mikoFont}>M IKO</span>. <br />
                    <small className="text-muted">
                      Zgoda na przetwarzanie danych osobowych zgodnie z Ustawą z
                      dnia 29 sierpnia 1997r o ochronie danych osobowych (Dz. U.
                      Nr 133, poz. 833). Administratorem danych osobowych jest
                      Irmina Konarzewska.
                    </small>
                  </label>
                </div>
              </div>
              <div className="row-fluid">
                <div className="form-check">
                  <input
                    required={true}
                    className="form-check-input border border-danger"
                    type="checkbox"
                    name="zdrowie"
                    onClick={(e) => {
                      setIsHealthy(e.target.checked);
                    }}
                  />
                  <label className="form-check-label">
                    Potwierdzam, że moje dziecko jest zdrowe i nie ma
                    przeciwwskazań lekarskich do udziału w lekcjach pływania.
                  </label>
                </div>
              </div>
              <div className="row-fluid">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="zdjecia"
                    onClick={(e) => {
                      setIsPhotoOk(e.target.checked);
                    }}
                  />
                  <label className="form-check-label">
                    Zgadzam się na publikacje zdjęć mojego dziecka w Internecie
                  </label>
                </div>
              </div>
            </section>
          </Fragment>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-primary" onClick={handleClose}>
          Zamknij
        </button>
        <button
          className="btn btn-success"
          onClick={addParticipantToGroup}
          disabled={isSubmitEnabled()}
        >
          {addStatus === "loading" && (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}{" "}
          Zapisz
        </button>

        {shouldShowApiError(addError) && (
          <div className="row mt-3 justify-content-center">
            <div className="col">
              <div className="alert alert-danger" role="alert">
                {addError}
              </div>
            </div>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};
