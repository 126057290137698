import React from "react";

export const GroupSelect = ({ groups, selectionHandler }) => {
  return (
    <select
      className="form-select"
      onChange={(e) => {
        selectionHandler(e.target.value)
      }}
    >
      <option></option>
      {groups.map((listValue, index) => {
        return (
          <option key={index} value={listValue.id}>
            {listValue.pool} {listValue.day} {listValue.time}
          </option>
        );
      })}
    </select>
  );
};
