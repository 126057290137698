import React, { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removeGroupParticipantsData } from "../../services/groups-slice";
import { RemoveSuccess } from "./removeSuccess";

export const RemoveParticipant = () => {
  let { userID } = useParams();
  let { groupID } = useParams();

  const dispatch = useDispatch();
  const removeStatus = useSelector((state) => state.groups.statusRemoveData);
  const removeError = useSelector((state) => state.groups.errorRemoveData);
  const [email, setEmail] = useState(undefined);
  const [shouldShowSuccess, setShouldShowSuccess] = useState(false);
  const isShowSuccess = removeStatus === "succeeded" && shouldShowSuccess;

  const shouldShowApiError = (errorMsg) => {
    if (errorMsg === undefined) {
      return false;
    }

    return errorMsg !== "unknown";
  };
  const removeParticipantsData = () => {
    dispatch(removeGroupParticipantsData({ groupID, userID, email }));
    setShouldShowSuccess(true);
  };
  return (
    <Fragment>
      <div className="row d-flex justify-content-center pt-5">
        <div className="col-5">
          <RemoveSuccess shouldShow={isShowSuccess} />
          {!isShowSuccess && (
            <div className="card shadow mt-3 mb-3">
              <div className="card-body">
                <h5 className="card-title">Wykasowanie Danych Osobowych</h5>
                <section>
                  <div className="form-group">
                    <label>Potwierdź adres email</label>
                    <input
                      className="form-control"
                      type="email"
                      autoComplete="email"
                      placeholder="jan@kowalski.pl"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>
                  <button
                    className="btn btn-primary mt-3"
                    onClick={removeParticipantsData}
                  >
                    {removeStatus === "loading" && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}{" "}
                    Zatwierdź
                  </button>
                  {shouldShowApiError(removeError) && (
                    <div className="row mt-3 justify-content-center">
                      <div className="col">
                        <div className="alert alert-danger" role="alert">
                          {removeError}
                        </div>
                      </div>
                    </div>
                  )}
                </section>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};
