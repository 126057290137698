import React from 'react';
import { Link } from "react-router-dom";

export const Footer = () => {
  const mikoFontStyle = {
    "fontFamily": "kids",
  }

  return (
    <footer className="footer">
      <div className="container-fluid text-muted">
        <div className="row g-0">
          <div className="col-sm-2 offset-md-1 offset-lg-2">
            <h5><span style={mikoFontStyle}>M IKO</span></h5>
            <ul className="list-unstyled">
              <li>
                <Link className="nav-link" to={""}>
                  O Nas
                </Link>
              </li>
              <li>
                <Link className="nav-link" to={"roster"}>
                  Kadra
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-sm-2">
            <h5>Zajęcia</h5>
            <ul className="list-unstyled">
              <li>
                <Link className="nav-link" to={"offer"}>
                  Oferta
                </Link>
              </li>
              <li>
                <Link className="nav-link" to={"register"}>
                  Zapisy
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-sm-2">
            <h5>Pływalnie</h5>
            <ul className="list-unstyled">
              <Link className="nav-link" to={"pools"}>
                Koncertowa
              </Link>
            </ul>
          </div>
          <div className="col-2">
            <h5>Pytania</h5>
            <ul className="list-unstyled">
              <li>
                <Link className="nav-link" to={"faq"}>
                  FAQ
                </Link>
              </li>
              <li>
                <Link className="nav-link" to={"contact"}>
                  Kontakt
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-sm-2">
            <h5>Social</h5>
            <ul className="list-unstyled">
              <li>
                <a
                  href="https://www.facebook.com/mikoplywanie"
                  title="mikoplywanie"
                  target="_blank">
                  <img
                    className="footer-img"
                    src={'https://storage.googleapis.com/miko-plywanie-public/icons/facebook.png'}
                    alt="Fb"
                  />
                  {" "}Facebook
                </a>
              </li>
              <li>
                <a href="https://twitter.com/MIKOplywanie"
                   title="@MIKOplywanie"
                   target="_blank">
                  <img
                    className="footer-img"
                    src={'https://storage.googleapis.com/miko-plywanie-public/icons/twitter.png'}
                    alt="Twitter"
                  />
                  {" "}Twitter
                </a>
              </li>
              <li>
                <a href="http://www.instagram.com/mikoplywanie"
                   title="mikoplywanie"
                   target="_blank">
                  <img
                    className="footer-img"
                    src={'https://storage.googleapis.com/miko-plywanie-public/icons/instagram.png'}
                    alt="Insta"/>
                  {" "}Instagram
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row g-0">
          <div className="col text-center">
            <p className="text-muted" style={mikoFontStyle}>
              &copy; 1991 - {new Date().getFullYear()} M IKO Pływanie
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};
