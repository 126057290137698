import React, { Fragment } from "react";

export const Closed = () => {
  const mikoFontStyle = {
    "fontFamily": "kids",
  }
  return (
    <Fragment>
      <div className="alert alert-info lead">
        Dziękujemy za zainteresowanie naszymi zajęciami!
        <br />
        <br />
        <span className="text-error">
          Zapisy na następny rok szkolny jeszcze nie otwarte!
        </span>
        <br />
        <br />
        Gorąco zapraszamy,
        <br/>
        <br/>
        Trenerzy <span style={mikoFontStyle}>M IKO</span>
      </div>
    </Fragment>
  );
};
