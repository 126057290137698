import React, { Fragment } from "react";
import Parser from "html-react-parser";
import { HeadingRow } from "./heading-row";
import { ImageRow } from "./image-row";

export const ContentRow = ({
  header,
  content,
  contentHeader,
  image,
  headerColor,
  isImgLeft,
  textAlignment = "text-center",
}) => {
  return (
    <Fragment>
      <section>
        <HeadingRow headerColor={headerColor} header={header} />
        <div className="container marketing">
          <div className="row g-0 featurette justify-content-center align-items-center">
            {isImgLeft ? (
              <Fragment>
                <div className="col-sm-5">
                  {image && <ImageRow image={image} />}
                </div>
                <div className={`col-sm-7 ${textAlignment}`}>
                  {contentHeader && Parser(contentHeader)}
                  {Parser(content)}
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <div className={`col-sm-7 ${textAlignment}`}>
                  {contentHeader && Parser(contentHeader)}
                  {Parser(content)}
                </div>
                <div className="col-sm-5">
                  {image && <ImageRow image={image} />}
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </section>
    </Fragment>
  );
};
