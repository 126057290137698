import React, { Fragment } from "react";

export const ImageRow = ({image}) => {
  return (
    <img
      className="featurette-image rounded large-img img-fluid mt-3 mb-3"
      src={image}
      alt={image}
    />
  )
}