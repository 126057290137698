import React from "react";
import Parser from "html-react-parser";

export const HeadingRow = ({ headerColor, header }) => {
  return (
    <div className="row g-0">
      <div className={"col text-center colorhead " + headerColor}>
        <h2>
          {Parser(header)}
        </h2>
      </div>
    </div>
  )
}