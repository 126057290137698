import React, { Fragment } from 'react';
import { Footer } from './components/footer/footer';
import { MainNav } from './components/main-nav/main-nav';
import { Outlet } from 'react-router-dom';

function App() {
  return (
    <Fragment>
      <MainNav />
      <Outlet />
      <Footer />
    </Fragment>
  );
}

export default App;
