import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchPage } from "./services/pages-slice";

export const usePageContent = (pageName) => {
  const dispatch = useDispatch();
  const data = useSelector((state) =>
    state.pages.data.find((x) => x.name === pageName)
  );
  const pageData = data && data.data;
  const pageStatus = useSelector((state) => state.pages.statusFetch);

  useEffect(() => {
    if (pageData === undefined || pageData.length === 0) {
      dispatch(fetchPage({ pageName: pageName }));
    }
  }, []);

  return {
    pageData,
    pageStatus,
  };
};
