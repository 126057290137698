import React, { useEffect } from "react";
import { ProgressBar } from "react-bootstrap";
import { GroupSelect } from "./groupSelect";
import { useDispatch, useSelector } from "react-redux";
import { fetchGroupCount, fetchGroups } from "../../services/groups-slice";
import { Spinner } from "../../components/spinner/spinner";

export const StepTwo = ({
  groupID,
  setGroupID,
  difficulty,
  shouldDisplay,
  setShouldDisplay,
}) => {
  const dispatch = useDispatch();
  const groupsData = useSelector((state) => state.groups.data);
  const groupCount = useSelector((state) => state.groups.groupCount);
  const isWaitList = (groupID, groupCount) => {
    if (!groupID) {
      return false;
    }
    let capacity = groupsData.find((x) => x.id === parseInt(groupID)).capacity;

    return groupCount >= capacity;
  };

  useEffect(() => {
    if (groupsData === undefined || groupsData.length === 0) {
      dispatch(fetchGroups());
    }
  });

  useEffect(() => {
    if (groupID) {
      dispatch(fetchGroupCount({ groupID }));
    }
  }, [groupID]);

  return (
    <fieldset className={shouldDisplay === 1 ? "" : "d-none"}>
      <legend>Krok 2.</legend>
      <hr />
      <ProgressBar striped variant="info" now={66} />
      <section className={"mt-3"}>
        <div className="row-fluid">
          <label>
            Wybierz zajęcia najbardziej Ci odpowiadające, spośród zajęć, które
            znaleźlismy dla Ciebie:
          </label>
          <div className="span6">
            {groupsData === undefined ? (
              <div className="d-flex justify-content-center">
                <Spinner />
              </div>
            ) : (
              <GroupSelect
                groups={groupsData.filter(
                  (x) => x.levelID === parseInt(difficulty)
                )}
                difficulty={difficulty}
                selectionHandler={setGroupID}
              />
            )}
          </div>
          {isWaitList(groupID, groupCount) && (
            <div className="alert alert-danger mt-3" role="alert">
              <strong> Uwaga! </strong>Lista rezerwowa
            </div>
          )}
        </div>
      </section>
      <br />
      <div className="row justify-content-between">
        <div className="col-3">
          <button
            className="btn btn-warning mt-2"
            onClick={() => setShouldDisplay(0)}
          >
            Krok 1
          </button>
        </div>
        <div className="col-3">
          <button
            disabled={!groupID}
            className="btn btn-success mt-2"
            onClick={() => setShouldDisplay(2)}
          >
            Krok 3
          </button>
        </div>
      </div>
    </fieldset>
  );
};
