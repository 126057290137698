import React, { Fragment } from "react";
import { PageHeading } from "../components/page-heading/page-heading";
import { ContentRow } from "../components/content-row/content-row";
import { Spinner } from "../components/spinner/spinner";
import { usePageContent } from "../hooks";

export const Kontakt = () => {
  const pageName = "kontakt";
  const { pageStatus, pageData } = usePageContent(pageName);

  return (
    <Fragment>
      <PageHeading
        image="https://storage.googleapis.com/miko-plywanie-public/images/kontakt.jpg"
        header="<h1>Z Nami Zawsze Możesz </h1>
	              <h2> <span className='text-muted'>Porozmawiać  o Pływaniu</span></h2>"
      />
      {pageStatus === "loading" || !pageData ? (
        <div className="d-flex justify-content-center">
          <Spinner />
        </div>
      ) : (
        <Fragment>
          <ContentRow
            header="<h2>Kto Pyta <br> Nie Błądzi</h2>"
            contentHeader="<h3>Wszystkie pytania na temat zajęć <br> <span className='text-muted'> możesz kierować do mnie</span></h3>"
            content={pageData[1].name}
            image={
              "https://storage.googleapis.com/miko-plywanie-public/images/pytania.jpg"
            }
            headerColor={"red"}
            isImgLeft={false}
          />
          <ContentRow
            header="<h2>Praca w Grupie<br>Przynosi Najlepsze Rezultaty</h2>"
            contentHeader="<h3> Zadaj pytanie <span className='text-muted'>nie tylko do nas</span></h3>"
            content={pageData[2].name}
            image={
              "https://storage.googleapis.com/miko-plywanie-public/images/pytania2.jpg"
            }
            headerColor={"yellow"}
            isImgLeft={true}
          />
        </Fragment>
      )}
    </Fragment>
  );
};
