import React from "react";
import { Alert } from "react-bootstrap";

export const AddError = ({shouldShow, setShouldShow}) => {
  const mikoFont = {
    fontFamily: "kids",
  };

  if (shouldShow) {
    return (
      <Alert variant="danger" onClose={() => setShouldShow(false)} dismissible>
      <span className="text-error">
        <strong>Problem!</strong>
      </span>
        <br />
        Rejestracja nie powiodła się!
        <br />
        Prosimy wysłac email na
        <a href="mailto:miko@miko-plywanie.pl"> miko@miko-plywanie.pl</a> lub sms
        na numer 503 940 590 <br />
        <span style={mikoFont}>M IKO</span>
      </Alert>
    );
  }
};
