import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addGroupParticipant,
  resetAddState,
} from "../../services/groups-slice";
import { StepOne } from "./stepOne";
import { StepTwo } from "./stepTwo";
import { StepThree } from "./stepThree";
import { AddSuccess } from "./addSuccess";
import { AddError } from "./addError";

export const RegistrationForm = () => {
  const dispatch = useDispatch();
  const addStatus = useSelector((state) => state.groups.statusAddParticipant);
  const addError = useSelector((state) => state.groups.errorAddParticipant);
  const [difficulty, setDifficulty] = useState(undefined);
  const [groupID, setGroupID] = useState(undefined);
  const [first, setFirst] = useState(undefined);
  const [last, setLast] = useState(undefined);
  const [email, setEmail] = useState(undefined);
  const [phone, setPhone] = useState(undefined);
  const [isHealthy, setIsHealthy] = useState(false);
  const [isPhotoOk, setIsPhotoOk] = useState(false);
  const [shouldDisplay, setShouldDisplay] = useState(0);
  const [showSuccess, setShowSuccess] = useState(true);
  const [showFail, setShowFail] = useState(true);

  const setShouldDisplayWithStateReset = (step) => {
    setShouldDisplay(step);
    dispatch(resetAddState());
  };

  const setShouldShowSuccess = (value) => {
    setShowSuccess(value);
    setShouldDisplayWithStateReset(0);
  };
  const setShouldShowFailure = (value) => {
    setShowFail(value);
    setShouldDisplayWithStateReset(0);
  };

  const addParticipantToGroup = () => {
    dispatch(
      addGroupParticipant({
        groupID,
        first,
        last,
        email,
        phone,
        isHealthy,
        isPhotoOk,
      })
    );
    setShowSuccess(true);
    setShowFail(true);
  };
  const updateDifficulty = (value) => {
    setDifficulty(value);
    setGroupID(undefined);
  };
  const shouldShowSuccess = addStatus === "succeeded" && showSuccess;
  const shouldShowFailure =
    addStatus === "failed" && showFail && addError === "unknown";

  return (
    <Fragment>
      <h3>
        Zapisy <span className="text-muted">Online</span>
      </h3>
      <p className="lead">Zapisz się na zajęcia w 3 prostych krokach</p>
      <AddSuccess
        setShouldShow={setShouldShowSuccess}
        shouldShow={shouldShowSuccess}
      />
      <AddError
        setShouldShow={setShouldShowFailure}
        shouldShow={shouldShowFailure}
      />
      {!shouldShowFailure && !shouldShowSuccess && (
        <section className="form-horizontal">
          <StepOne
            setDifficulty={updateDifficulty}
            difficulty={difficulty}
            shouldDisplay={shouldDisplay}
            setShouldDisplay={setShouldDisplayWithStateReset}
          />
          <StepTwo
            setGroupID={setGroupID}
            difficulty={difficulty}
            shouldDisplay={shouldDisplay}
            setShouldDisplay={setShouldDisplayWithStateReset}
            groupID={groupID}
          />
          <StepThree
            setFirst={setFirst}
            setLast={setLast}
            setPhone={setPhone}
            setEmail={setEmail}
            setIsPhotoOk={setIsPhotoOk}
            setIsHealthy={setIsHealthy}
            addParticipantToGroup={addParticipantToGroup}
            shouldDisplay={shouldDisplay}
            setShouldDisplay={setShouldDisplayWithStateReset}
            first={first}
            last={last}
            phone={phone}
            email={email}
            isHealthy={isHealthy}
            addError={addError}
            isLoading={addStatus === "loading"}
          />
        </section>
      )}
    </Fragment>
  );
};
