import React, { Fragment, useEffect, useState } from "react";
import { PageHeading } from "../../components/page-heading/page-heading";
import { HeadingRow } from "../../components/content-row/heading-row";
import { useDispatch, useSelector } from "react-redux";
import { ContentRowWithTable } from "../../components/content-row/content-row-with-table";
import { Spinner } from "../../components/spinner/spinner";
import { fetchGroups, resetAddState } from "../../services/groups-slice";
import { fetchIsRegistrationOpen } from "../../services/settings-slice";
import { RegistrationModal } from "./registrationModal";
import { usePageContent } from "../../hooks";

export const Offer = () => {
  const [show, setShow] = useState(false);
  const [modalGroupID, setModalGroupID] = useState(undefined);
  const [modalGroupCapacity, setModalGroupCapacity] = useState(undefined);
  const handleClose = () => setShow(false);
  const handleShow = (groupID, groupCapacity) => {
    setShow(true);
    setModalGroupCapacity(groupCapacity);
    setModalGroupID(groupID);
    dispatch(resetAddState());
  };
  const dispatch = useDispatch();
  const pageName = "oferta";
  const { pageStatus, pageData } = usePageContent(pageName);
  const isRegistrationOpen = useSelector(
    (state) => state.settings.isRegistrationOpen
  );
  const groupsData = useSelector((state) => state.groups.data);
  const adultGroups = groupsData.filter(
    (x) => x.levelID === 5 || x.levelID === 6 || x.levelID === 9
  );
  useEffect(() => {
    if (groupsData === undefined || groupsData.length === 0) {
      dispatch(fetchGroups());
    }
  }, []);
  useEffect(() => {
    if (isRegistrationOpen === undefined) {
      dispatch(fetchIsRegistrationOpen());
    }
  }, []);

  return (
    <Fragment>
      <RegistrationModal
        shouldShow={show}
        handleClose={handleClose}
        groupCapacity={modalGroupCapacity}
        groupID={modalGroupID}
      />
      <PageHeading
        image="https://storage.googleapis.com/miko-plywanie-public/images/toys.png"
        header="<h1>Nasza Oferta</h1>
                <h2><span className='text-muted'> Jest Dla Każdego</span></h2>"
      />
      {pageStatus === "loading" || !pageData || !groupsData ? (
        <div className="d-flex justify-content-center">
          <Spinner />
        </div>
      ) : (
        <Fragment>
          <HeadingRow
            headerColor="pink"
            header="<h2> Pierwsze Kroki <br> Sa Najważniejsze</h2>"
          />
          <ContentRowWithTable
            contentHeader="<h3>Dzieci <span className='text-muted'>oraz</span> Młodzież</h3>
              <p className='lead'> Nauka pływania od podstaw (od 5lat)</p>"
            content={pageData[1].name}
            image="https://storage.googleapis.com/miko-plywanie-public/images/nauka.jpg"
            isImgLeft={true}
            tableContent={groupsData.filter((x) => x.levelID === 1)}
            levelID={1}
            isRegistrationOpen={isRegistrationOpen}
            handleShowModal={handleShow}
          />
          <hr className="w-50 mx-auto" />
          <ContentRowWithTable
            contentHeader="<p class='lead'>Kontynuacja nauki pływania. </br> Dzieci po 1 roku nauki pływania
            <span style='font-family:kids'>M IKO</span>. </p>"
            content={pageData[1].name}
            image="https://storage.googleapis.com/miko-plywanie-public/images/kontynuacja.jpg"
            isImgLeft={false}
            tableContent={groupsData.filter((x) => x.levelID === 7)}
            levelID={7}
            isRegistrationOpen={isRegistrationOpen}
            handleShowModal={handleShow}
          />
          <HeadingRow
            headerColor="red"
            header="<h2> Technika <br> to Podstawa</h2>"
          />
          <ContentRowWithTable
            contentHeader="<h3>Doskonalenie techniki <span className='text-muted'>pływania u</span><br/> dzieci i młodzieży</h3>
          <p className='lead'> Zajęcia odbywają się na trzech poziomach:<br>
            Doskonalenie, pływam już 1-2 stylami <span className='green greentext'>___</span><br>
          </p>"
            content={pageData[2].name}
            image="https://storage.googleapis.com/miko-plywanie-public/images/doskonalenie2.jpg"
            isImgLeft={true}
            tableContent={groupsData.filter((x) => x.levelID === 2)}
            levelID={2}
            isRegistrationOpen={isRegistrationOpen}
            handleShowModal={handleShow}
          />
          <hr className="w-50 mx-auto" />
          <ContentRowWithTable
            contentHeader="<p className='lead'>
            Doskonalenie, pływam już 3-4 stylami <span className='red redtext'>___</span><br>
          </p>"
            content={pageData[3].name}
            image="https://storage.googleapis.com/miko-plywanie-public/images/doskonalenie4.jpg"
            isImgLeft={false}
            tableContent={groupsData.filter((x) => x.levelID === 3)}
            levelID={3}
            isRegistrationOpen={isRegistrationOpen}
            handleShowModal={handleShow}
          />
          <hr className="w-50 mx-auto" />
          <ContentRowWithTable
            contentHeader="<p className='lead'>
              Pływanie zaawansowane, doskonalę sportową technikę pływania. <span className='yellow yellowtext'>___</span><br>
            </p>"
            content={pageData[3].name}
            image="https://storage.googleapis.com/miko-plywanie-public/images/zaawansowane.jpg"
            isImgLeft={true}
            tableContent={groupsData.filter((x) => x.levelID === 8)}
            levelID={8}
            isRegistrationOpen={isRegistrationOpen}
            handleShowModal={handleShow}
          />
          {adultGroups.length > 0 && (
            <Fragment>
              <HeadingRow
                headerColor="green"
                header="<h2> Pływanie <br> to Zdrowie</h2>"
              />
              <ContentRowWithTable
                contentHeader="<h3>Dorośli<span className='text-muted'> oraz</span> Seniorzy</h3>
        <p className='lead'>Zajęcia odbywają się na czterech poziomach zaawansowania:<br>
          -Nauka pływania <span className='blue bluetext'>___</span><br>
          -Doskonalenie, pływam już 1-2 stylami <span className='green greentext'>___</span><br>
          -Doskonalenie, pływam już 3-4 stylami <span className='red redtext'>___</span><br>
          -Pływanie zaawansowane, doskonalę sportową technikę pływania. <span className='yellow yellowtext'>___</span><br>
        </p>"
                content={pageData[4].name}
                image="https://storage.googleapis.com/miko-plywanie-public/images/dorosli.jpg"
                isImgLeft={false}
                isRegistrationOpen={isRegistrationOpen}
                handleShowModal={handleShow}
              />
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};
