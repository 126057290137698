import React, { Fragment } from "react";
import Parser from "html-react-parser";

export const PageHeading = ({ image, header }) => {
  const backgroundImageStyle = {
    backgroundImage: `url("${image}")`,
  };

  return (
    <Fragment>
      <div
        className="titlePic title colorhead"
        style={backgroundImageStyle}
      >
        <div className="pull-left titletxt">
          {Parser(header)}
        </div>
      </div>
    </Fragment>
  )
}