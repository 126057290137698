import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./services/store";
import App from "./App";
import { Home } from "./pages/home";
import { NoMatch } from "./pages/no-match/no-match";
import { Kontakt } from "./pages/kontakt";
import { Offer } from "./pages/offer/offer";
import { Kadra } from "./pages/kadra";
import { FAQ } from "./pages/faq";
import { Zapisy } from "./pages/registration/zapisy";
import { Pools } from "./pages/pools";
import { RemoveParticipant } from "./pages/remove-participant/remove-participant";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Home />} />
          <Route path="roster" element={<Kadra />} />
          <Route path="offer" element={<Offer />} />
          <Route path="pools" element={<Pools />} />
          <Route path="register" element={<Zapisy />} />
          <Route path="contact" element={<Kontakt />} />
          <Route path="faq" element={<FAQ />} />
          <Route
            path="groups/:groupID/participants/:userID"
            element={<RemoveParticipant />}
          />
          <Route path="*" element={<NoMatch />} />
        </Route>
        <Route component={<NoMatch />} />
      </Routes>
    </BrowserRouter>
  </Provider>
);
