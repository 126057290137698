import React from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

export const MainNav = () => {
  return (
    <div className="container">
      <Navbar expand="lg">
        <Navbar.Brand href="/">
          <Link className="nav-link" to={"/"}>
            <img
              src={
                "https://storage.googleapis.com/miko-plywanie-public/icons/mikoNav.png"
              }
              width="150"
              height="36"
              className="d-inline-block align-top"
              alt="MIKO logo"
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto mb-2 mb-lg-0">
            <Link className="nav-link" to={""}>
              O Nas
            </Link>
            <Link className="nav-link" to={"roster"}>
              Kadra
            </Link>
            <NavDropdown title="Zajęcia" id="basic-nav-dropdown">
              <NavDropdown.Item>
                <Link className="nav-link" to={"offer"}>
                  Oferta
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item>
                <Link className="nav-link" to={"pools"}>
                  Koncertowa >> Ursynów
                </Link>
              </NavDropdown.Item>
            </NavDropdown>
            <Link className="nav-link" to={"register"}>
              Zapisy
            </Link>
            <Link className="nav-link" to={"contact"}>
              Kontakt
            </Link>
            <Link className="nav-link" to={"faq"}>
              FAQ
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};
