import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Parser from "html-react-parser";
import { Link } from "react-router-dom";

export const HomeCarousel = ({ captionContents }) => {
  const style = {
    width: "100vw",
  };
  const styleHeight = {
    height: "auto",
    width: "100vw",
    verticalAlign: "middle",
  };
  const captionStyle = {
    color: "white",
    marginTop: "5px",
  };
  return (
    <Carousel style={styleHeight}>
      <Carousel.Item>
        <img
          style={style}
          src={
            "https://storage.googleapis.com/miko-plywanie-public/images/banner1.jpg"
          }
          alt="First slide"
        />
        <Carousel.Caption bsPrefix="carousel-caption d-none d-md-block">
          <h3 style={captionStyle}>Przypominamy</h3>
          <strong>{Parser(captionContents[0])}</strong>
          <Link to="/faq">
            <span className="btn btn-primary mt-10">Dowiedz się więcej</span>
          </Link>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          style={style}
          src={
            "https://storage.googleapis.com/miko-plywanie-public/images/banner_zapisy.jpg"
          }
          alt="Third slide"
        />
        <Carousel.Caption bsPrefix="carousel-caption d-none d-md-block">
          <h3 style={captionStyle}>Rok szkolny 2024/2025</h3>
          <strong>{Parser(captionContents[1])}</strong>
          <Link to="/register">
            <span className="btn btn-primary mt-10">Zapisz się dziś</span>
          </Link>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          style={style}
          src={
            "https://storage.googleapis.com/miko-plywanie-public/images/banner_social.jpg"
          }
          alt="Third slide"
        />
        <Carousel.Caption bsPrefix="carousel-caption d-none d-md-block">
          <h3 style={captionStyle}>Bądź na czasie</h3>
          <strong>{Parser(captionContents[2])}</strong>
          <br />
          <a className="btn btn-primary mt-10" href="#social">
            Dowiedz się więcej
          </a>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};
