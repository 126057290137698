import React, { Fragment } from "react";
import { HomeCarousel } from "../components/carousel/home-carousel";
import { ContentRow } from "../components/content-row/content-row";
import { Spinner } from "../components/spinner/spinner";
import { usePageContent } from "../hooks";

export const Home = () => {
  const pageName = "main";
  const { pageStatus, pageData } = usePageContent(pageName);

  return (
    <Fragment>
      <div className="row g-0">
        <div className="col">
          {!pageData ? (
            <div className="row g-0 d-flex justify-content-center">
              <Spinner />
            </div>
          ) : (
            <HomeCarousel
              captionContents={[
                pageData[6].name,
                pageData[7].name,
                pageData[8].name,
              ]}
            />
          )}
        </div>
      </div>
      {pageStatus === "loading" || !pageData ? (
        <div className="row g-0 d-flex justify-content-center">
          <Spinner />
        </div>
      ) : (
        <Fragment>
          <ContentRow
            header="<span style='font-family:kids;'>M IKO </span> <br> Szkoła Pływania Stworzona z Pomysłem"
            contentHeader="<h3 className='span6'>Nasza Historia <span className='text-muted'>Nie Jest Nudna</span></h3>"
            content={pageData[1].name}
            image={
              "https://storage.googleapis.com/miko-plywanie-public/images/mikoLessColors38.png"
            }
            headerColor={"green"}
            isImgLeft={false}
          />
          <ContentRow
            header="Uczymy <br>bo Lubimy"
            contentHeader="<h3 className='span6'>Zajmujemy Się<span className='text-muted'>  Tym co Kochamy</span></h3>"
            content={pageData[2].name}
            image={
              "https://storage.googleapis.com/miko-plywanie-public/images/kochamy.jpg"
            }
            headerColor={"red"}
            isImgLeft={true}
          />
          <ContentRow
            header="Innowacyjność <br> i Oryginalność"
            contentHeader="<h3 className='span6'>Nasze Metody <span className='text-muted'> Są Niepowtarzalne</span></h3>"
            content={pageData[3].name}
            image={
              "https://storage.googleapis.com/miko-plywanie-public/images/metody.jpg"
            }
            headerColor={"yellow"}
            isImgLeft={false}
          />
          <ContentRow
            header="Niepowtarzalna <br> Kadra"
            contentHeader="<h3 className='span6'>Nasze Doświadczenie <span className='text-muted'> Przynosi Rezultaty</span></h3>"
            content={pageData[4].name}
            image={
              "https://storage.googleapis.com/miko-plywanie-public/images/pol.jpg"
            }
            headerColor={"blue"}
            isImgLeft={true}
          />
          <ContentRow
            header="Zajęcia na Pływalni <br> to Dopiero Początek"
            contentHeader="<h3 className='span6'>Lubimy być aktywni<span className='text-muted'> Nie tylko na Pływalni</span></h3>"
            content={pageData[5].name}
            image={
              "https://storage.googleapis.com/miko-plywanie-public/images/social.jpg"
            }
            headerColor={"pink"}
            isImgLeft={false}
          />
        </Fragment>
      )}
    </Fragment>
  );
};
